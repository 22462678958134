var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('h2', [_vm._v("Historical Activity - Touchpoints")]), _c('br'), _c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "load-dictionaries": _vm.loadDictionaries,
      "dataset-name": "sales-team/historical-activity",
      "show-labels": false,
      "compact": true,
      "loaded": _vm.filteringPanel.loaded,
      "districts": _vm.filteringPanel.districts,
      "filters": _vm.filteringPanel.filters
    },
    on: {
      "dict-loaded": _vm.onFilteringPanelLoad,
      "change": _vm.onFilteringPanelChange,
      "search": _vm.getData,
      "reset": _vm.onFilteringPanelReset
    }
  })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('g-chart-custom', {
    ref: "chart1",
    attrs: {
      "title": "Weekly activity",
      "subtitle": "All touchpoints by activity date",
      "type": "ColumnChart",
      "data": _vm.charts.chart1.chartData,
      "loading": _vm.charts.chart1.loading,
      "options": _vm.charts.chart1.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart1
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart1-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-1 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart1-ddi"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('g-chart-custom', {
    ref: "chart3",
    attrs: {
      "title": "Account engagement",
      "subtitle": "All touchpoints by account order regularity",
      "type": "ColumnChart",
      "data": _vm.charts.chart3.chartData,
      "loading": _vm.charts.chart3.loading,
      "options": _vm.charts.chart3.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart3,
      "ready": _vm.onGoogleChartReady
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart3-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-1 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart3-ddi"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('g-chart-custom', {
    ref: "chart2",
    attrs: {
      "title": "Weekly report",
      "subtitle": "Report submitted",
      "type": "Timeline",
      "settings": _vm.charts.chart2.settings,
      "data": _vm.charts.chart2.chartData,
      "loading": _vm.charts.chart2.loading,
      "options": _vm.charts.chart2.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart2
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart2-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-1 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart2-ddi"
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', {
    attrs: {
      "id": "timeline"
    }
  })], 1), _c('b-modal', {
    ref: "weekly-report-modal",
    attrs: {
      "hide-footer": "",
      "title": _vm.charts.chart2.report.title
    }
  }, [_c('div', {
    staticClass: "weekly-report",
    domProps: {
      "innerHTML": _vm._s(_vm.charts.chart2.report.htmlContent)
    }
  })])], 1), _c('hr')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }