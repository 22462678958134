<template>
  <div class="animated fadeIn">
    <b-card>
      <h2>Historical Activity - Touchpoints</h2>
      <br />
      <b-row>
        <b-col>
          <filtering-panel
            ref="filteringPanel"
            mode="server"
            :load-dictionaries="loadDictionaries"
            dataset-name="sales-team/historical-activity"
            :show-labels="false"
            :compact="true"
            :loaded="filteringPanel.loaded"
            :districts="filteringPanel.districts"
            :filters="filteringPanel.filters"
            @dict-loaded="onFilteringPanelLoad"
            @change="onFilteringPanelChange"
            @search="getData"
            @reset="onFilteringPanelReset"
          />
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col lg="6">
          <g-chart-custom
            ref="chart1"
            title="Weekly activity"
            subtitle="All touchpoints by activity date"
            type="ColumnChart"
            :data="charts.chart1.chartData"
            :loading="charts.chart1.loading"
            :options="charts.chart1.chartOptions"
            @chart-element-selected="drilldownChart1"
          >
            <template #legend>
              <b-popover target="chart1-ddi" triggers="hover" placement="right">
                <span>Chart drilldown available</span>
              </b-popover>
              <i id="chart1-ddi" class="ml-1 fa fa-share fa-rotate-90" />
            </template>
          </g-chart-custom>
        </b-col>
        <b-col lg="6">
          <g-chart-custom
            ref="chart3"
            title="Account engagement"
            subtitle="All touchpoints by account order regularity"
            type="ColumnChart"
            :data="charts.chart3.chartData"
            :loading="charts.chart3.loading"
            :options="charts.chart3.chartOptions"
            @chart-element-selected="drilldownChart3"
            @ready="onGoogleChartReady"
          >
            <template #legend>
              <b-popover target="chart3-ddi" triggers="hover" placement="right">
                <span>Chart drilldown available</span>
              </b-popover>
              <i id="chart3-ddi" class="ml-1 fa fa-share fa-rotate-90" />
            </template>
          </g-chart-custom>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12">
          <g-chart-custom
            ref="chart2"
            title="Weekly report"
            subtitle="Report submitted"
            type="Timeline"
            :settings="charts.chart2.settings"
            :data="charts.chart2.chartData"
            :loading="charts.chart2.loading"
            :options="charts.chart2.chartOptions"
            @chart-element-selected="drilldownChart2"
          >
            <template #legend>
              <b-popover target="chart2-ddi" triggers="hover" placement="right">
                <span>Chart drilldown available</span>
              </b-popover>
              <i id="chart2-ddi" class="ml-1 fa fa-share fa-rotate-90" />
            </template>
          </g-chart-custom>
          <div id="timeline" />
        </b-col>

        <b-modal
          ref="weekly-report-modal"
          hide-footer
          :title="charts.chart2.report.title"
        >
          <div
            class="weekly-report"
            v-html="charts.chart2.report.htmlContent"
          />
        </b-modal>
      </b-row>
      <hr />
    </b-card>
  </div>
</template>

<script>
import moment from "moment";

import GChartCustom from "@/components/GChartCustom";

import FilteringPanel from "@/components/FilteringPanel";

export default {
  name: "Overview",
  components: {
    FilteringPanel,
    "g-chart-custom": GChartCustom
  },
  data: function() {
    return {
      isLoading: false,
      filteringPanel: {
        selected: {},
        districts: [],
        loaded: false,
        filters: [
          {
            type: "select",
            dataType: "territory",
            title: "Territory",
            tooltip: "Distributor billing territory",
            name: "territory",
            trackby: "id",
            label: "label",
            multiple: false,
            options: [],
            selected: {}
          },
          {
            type: "select",
            title: "Country",
            name: "country",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            ///
            dataType: "string",
            tooltip: "Country name",
            multiple: true
          },
          {
            type: "select",
            title: "State",
            name: "state",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            ///
            dataType: "string",
            tooltip: "State name",
            multiple: true
          },
          {
            type: "select",
            title: "City",
            name: "city",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            ///
            dataType: "string",
            tooltip: "State name",
            multiple: true
          },
          {
            type: "select",
            title: "Submitted By",
            name: "user",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            ///
            dataType: "string",
            tooltip: "User name",
            multiple: true
          },
          {
            type: "daterange",
            defaultRange: "Last quarter",
            title: "Sales period",
            name: "period",
            ///
            dataType: "datetime",
            tooltip: "Sales period",
            allowEmpty: false
          }
        ]
      },
      charts: {
        chart1: {
          loading: false,
          chartData: null,
          chartOptions: {
            height: 350,
            chartArea: {
              width: "80%",
              height: "70%"
            },
            title: "",
            subtitle: "",
            isStacked: true,
            orientation: "vertical",
            legend: {
              position: "bottom"
            },
            series: {
              0: {
                color: "#3366cc"
              },
              1: {
                color: "green"
              },
              2: {
                color: "orange"
              }
            }
          }
        },
        chart2: {
          loading: false,
          chartData: null,
          rawData: [],
          report: {
            title: "",
            htmlContent: ""
          },
          chartOptions: {
            tooltip: {
              trigger: "none"
            },
            height: 500,
            width: 0,
            chartArea: {
              width: "100%",
              height: "80%"
            },
            title: "",
            subtitle: "",
            isStacked: true,
            orientation: "vertical",
            legend: {
              position: "bottom"
            }
          },
          settings: {
            packages: ["corechart", "table", "timeline"]
          }
        },
        chart3: {
          loading: false,
          chartData: null,
          chartOptions: {
            height: 350,
            chartArea: {
              width: "70%",
              height: "70%"
            },
            title: "",
            subtitle: "",
            legend: {
              position: "none"
            }
          }
        }
      }
    };
  },
  mounted() {
    //  this.getData();
  },
  methods: {
    async loadDictionaries() {
      let self = this;

      const districts = async () => {
        let response = await this.$api.post("dictionaries/countries/mapped", {
          ignore_restrictions: false
        });

        self.districts = response;

        self.filteringPanel.districts = self.districts;

        self.filteringPanel.filters.find(
          f => f.name === "country"
        ).options = self.$helpers.getDistinctArray(
          response,
          "country",
          "country",
          "id",
          "label"
        );

        self.filteringPanel.filters.find(
          f => f.name === "state"
        ).options = self.$helpers.getDistinctArray(
          response,
          "state",
          "state",
          "id",
          "label"
        );

        self.filteringPanel.filters.find(
          f => f.name === "city"
        ).options = self.$helpers.getDistinctArray(
          response,
          "city",
          "city",
          "id",
          "label"
        );
      };
      const territories = async () => {
        let response = await this.$api.get("dictionaries/territories");

        self.filteringPanel.filters.find(
          f => f.name === "territory"
        ).options = response.map(u => ({
          id: u.ID,
          label: u.Name
        }));
      };

      const users = async () => {
        let response = await this.$api.get("users");

        self.filteringPanel.filters.find(
          f => f.name === "user"
        ).options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }));
      };

      await Promise.all([territories(), districts(), users()]);
    },
    onFilteringPanelLoad() {
      this.getData();
    },
    onFilteringPanelChange() {},
    onFilteringPanelReset() {
      this.$refs.filteringPanel.resetFilters({
        resetStorage: true
      });
    },
    onGoogleChartReady: function() {
      let filter = this.$refs.filteringPanel.selected;
      this.drawChart2(filter);
    },
    getData: function() {
      let f = this.$refs.filteringPanel.selected;

      let payload = {
        territory: f.territory ? f.territory : [],
        country: f.country ? f.country.map(i => i.label) : [],
        state: f.state ? f.state.map(i => i.label) : [],
        city: f.city ? f.city.map(i => i.label) : [],
        user: f.user ? f.user.map(i => i.id) : [],
        period: f.period
      };

      this.drawChart1(payload);

      this.drawChart3(payload);
    },
    drawChart1: async function(filter) {
      this.charts.chart1.loading = true;

      let response = await this.$api.data["SalesTeam.WeeklyActivity"](filter);

      this.charts.chart1.loading = false;

      if (response.length === 0) return;

      //chart 1
      let headers = [["Date", "Vendor", "Distributor", "Other"]];
      let data = [
        ...response.map(item => {
          return [
            moment(item.startdate).format("MMM DD, YYYY"),
            parseFloat(item.account_aty || 0),
            parseFloat(item.distributor_aty || 0),
            parseFloat(item.other_aty || 0)
          ];
        })
      ];

      this.charts.chart1.chartData = headers.concat(data);
    },
    drilldownChart1: function(e) {
      //let reportid = this.$helpers.uuidv4()
      let filter = this.$refs.filteringPanel.selected;

      //get selected chart elements
      filter.category = e.data[0][e.selection.column];
      filter.activitydate = moment
        .utc(e.data[e.selection.row + 1][0])
        .format("YYYY-MM-DD");

      //save data to storage
      let description = `${this.$refs.chart1.title} [Category: ${filter.category}; Activity Week Start: ${filter.activitydate}]`;
      this.$store.dispatch("drilldown.save", {
        filter: filter,
        parent: this.$route.name,
        description: description,
        method: "SalesTeam.WeeklyActivityDrilldown"
      });

      //open new page
      //api.data.SalesTeam.WeeklyActivityDrilldown(filter)
      this.$router.push({
        name: "Chart drilldown"
      });
    },
    drawChart2: async function(filter) {
      this.charts.chart2.loading = true;

      let response = await this.$api.data["SalesTeam.WeeklyReport"](filter);

      this.charts.chart2.loading = false;

      if (response.length === 0) return;

      this.charts.chart2.rawData = [
        ...response.map(item => {
          return [
            item.label,
            item.submitter,
            new Date(item.startdate),
            new Date(item.enddate),
            item.report
          ];
        })
      ];

      let data = [
        ...response.map(item => {
          return [
            item.label,
            item.reviewed === null ? "" : item.submitter,
            item.style,
            new Date(item.startdate),
            new Date(item.enddate)
          ];
        })
      ];

      //let headers = ['Label','Submitter','style','Start','End']
      //this.charts.chart2.chartData = headers.concat(data)

      //TODO wait until google library will be loaded - it is need to find smarter solution!
      //await this.$helpers.delay(3000);

      /*
            var container = document.getElementById('timeline');
            var chart = new google.visualization.Timeline(container);
            */

      //eslint-disable-next-line
      let dataTable = new google.visualization.DataTable();

      dataTable.addColumn({
        type: "string",
        id: "Label"
      });
      dataTable.addColumn({
        type: "string",
        id: "Submitter"
      });
      dataTable.addColumn({
        type: "string",
        role: "style"
      });
      dataTable.addColumn({
        type: "date",
        id: "Start"
      });
      dataTable.addColumn({
        type: "date",
        id: "End"
      });

      dataTable.addRows(data);

      let headers = [
        [
          {
            type: "string",
            id: "Label"
          },
          {
            type: "string",
            id: "Submitter"
          },
          {
            type: "string",
            role: "style",
            p: {
              role: "style"
            }
          },
          {
            type: "date",
            id: "Start"
          },
          {
            type: "date",
            id: "End"
          }
        ]
      ];

      if (data.length > 0)
        this.charts.chart2.chartOptions.height =
          [...new Set(data.map(item => item[0]))].length * 100;

      this.charts.chart2.chartOptions.width = this.$helpers.vw2px(80);

      this.charts.chart2.chartData = headers.concat(data);
    },
    drilldownChart2: function() {
      return;
      /*
      let name = this.charts.chart2.rawData[e.selection.row][1];
      let period_start = moment(
        this.charts.chart2.rawData[e.selection.row][2]
      ).format("MMM DD, YY");
      let period_end = moment(
        this.charts.chart2.rawData[e.selection.row][3]
      ).format("MMM DD, YY");

      this.charts.chart2.report.title = `Weekly report (${name}, ${period_start} - ${period_end})`;
      this.charts.chart2.report.htmlContent = this.charts.chart2.rawData[
        e.selection.row
      ][4];

      this.$refs["weekly-report-modal"].show();
      */
    },
    drawChart3: async function(filter) {
      this.charts.chart3.loading = true;

      let response = await this.$api.data["SalesTeam.AccountEngagement"](
        filter
      );

      this.charts.chart3.loading = false;

      if (response.length === 0) return;

      let item = response[0];

      let data = [
        ["Activity Type", "Count"],
        ["Lead", parseFloat(item.lead || 0)],
        ["New", parseFloat(item.new || 0)],
        ["Reactivated", parseFloat(item.reactivated || 0)],
        ["Active", parseFloat(item.active || 0)],
        ["Inactive", parseFloat(item.inactive || 0)],
        ["Distributor", parseFloat(item.distributor || 0)],
        ["Other", parseFloat(item.other || 0)]
      ];

      //if (data.length === 0)  data = [["", 0]]

      this.charts.chart3.chartData = data;
    },
    drilldownChart3: function(e) {
      let filter = this.$refs.filteringPanel.selected;

      //get selected chart elements
      filter.category = e.data[e.selection.row + 1][0];

      //save data to storage
      let description = `${this.$refs.chart3.title} [Category: ${filter.category}]`;
      this.$store.dispatch("drilldown.save", {
        filter: filter,
        parent: this.$route.name,
        description: description,
        method: "SalesTeam.AccountEngagementDrilldown"
      });

      //open new page
      this.$router.push({
        name: "Chart drilldown"
      });
    }
  },
  watch: {
    "filteringPanel.selected.country": function(newVal) {
      if (!newVal) return;

      this.filteringPanel.filters.find(
        f => f.name === "state"
      ).options = this.$helpers.getDistinctArray(
        this.districts.filter(s =>
          newVal.map(l => l.label).includes(s.country)
        ),
        "state",
        "state",
        "id",
        "label"
      );
    },

    "filteringPanel.selected.state": function(newVal) {
      if (!newVal) return;
      this.filteringPanel.filters.find(
        f => f.name === "city"
      ).options = this.$helpers.getDistinctArray(
        this.districts.filter(s => newVal.map(l => l.label).includes(s.state)),
        "city",
        "city",
        "id",
        "label"
      );
    }
  }
};
</script>

<style>
.weekly-report {
  height: 500px;
  width: 100%;
  overflow-y: scroll;
}
</style>
